import React, { useRef } from 'react';
//import { values } from 'regenerator-runtime';

export default class ImageComponent extends React.Component {
    constructor(props) {
        super(props);
    }

   handleChange(target, call) {
        this[target]();
   }

   mouseEnter() {
        const {header, content} = this.refs;    
        const travelAmmount = (content.offsetHeight - (content.offsetHeight / 4));
        
        if (window.innerWidth >= 768)
            header.style.transform = `translateY(-${travelAmmount}px) translateX(-50%)`;
   }

   mouseLeave() {
        const {header} = this.refs;

        header.style.transform = `translateY(0px) translateX(-50%)`;
   }

   addListener() {
        let observer = new IntersectionObserver((entry, observer) => { 
            if(entry.isIntersecting){
                console.log(entry);
                entry.target.src = entry.target.dataset.src;
                observer.unobserve(entry.target);
            }
        }, {rootMargin: "0px 0px -200px 0px"});
        observer.observe(img);
   }

    render() {
        return(
            <a href={this.props.link} onMouseEnter={this.handleChange.bind(this, 'mouseEnter')} onMouseLeave={this.handleChange.bind(this, 'mouseLeave')} className="vgb-imageSection__component" >
                <img src={this.props.src}></img>
                <h4 ref="header" className="vgb-imageSection__header">{this.props.title}</h4>
                <div ref="content" className="vgb-imageSection__content">
                    <p>{this.props.text}</p>
                    <span className="vgb-button">Lees meer</span>
                </div>
            </a>
        );
    }
}