import React from 'react';
import ReactDOM from 'react-dom';
import SimpleReactLightbox from 'simple-react-lightbox';
import { SRLWrapper } from 'simple-react-lightbox';

export default class GalleryController {
  constructor(target) {
    this.data = {
      container: target,
      galleryItems: [],
      galleryImages: [],
    };

    this.parseItems();

    this.createLightbox();
  }

  parseItems() {
    const { container } = this.data;
    const imageContainers = container.getElementsByClassName('wp-block-image');
    const images = container.querySelectorAll('.wp-block-image img');

    [].forEach.call(imageContainers, (container) => {
      this.data.galleryItems.push(container);
    });

    [].forEach.call(images, (image) => {
      this.data.galleryImages.push({
        src: image.src,
        width: 'auto',
        height: 'auto',
      });
    });
  }

  createLightbox() {
    ReactDOM.render(<Container images={this.data.galleryImages} />, this.data.container);
  }
}

function Container(images) {
  return (
    <div className="blocks-gallery">
      <SimpleReactLightbox>
        <LightBox images={images} />
      </SimpleReactLightbox>
    </div>
  );
}

function LightBox(images) {
  const options = {
    buttons: {
      showAutoplayButton: false,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: false,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: false,
    },
  };

  return (
    <SRLWrapper options={options}>
      <div className="blocks-gallery-grid" style={{ width: '100%' }}>
        {images.images.images.map((image, key) => (
          <div key={key} className="blocks-gallery-item">
            <figure>
              <img src={image.src} />
            </figure>
          </div>
        ))}
      </div>
    </SRLWrapper>
  );
}
