/**
 * ? FieldController
 * 
 * Controls state of the input fields
 * use for animation purposes
 */

class fieldController {

    constructor() {
        this.ninjaFields = document.querySelectorAll('input.ninja-forms-field, textarea.ninja-forms-field');
        this.addFormListeners();
        this.addSyles();
    }

    addFormListeners() {
        [].forEach.call(this.ninjaFields, (field) => {
            field.addEventListener('focus', (field) => {
                this.focusHandler(field);
            });
            field.addEventListener('blur', (field) => {
                this.blurHandler(field);
            });
        })
    }

    addSyles() {
        const styles = '.field-wrap .nf-field-label{position:relative;display:block;-webkit-transform:translateY(150%);transform:translateY(150%);-webkit-transition:-webkit-transform .4s ease-in-out;transition:-webkit-transform .4s ease-in-out;transition:transform .4s ease-in-out;transition:transform .4s ease-in-out, -webkit-transform .4s ease-in-out}.field-wrap[data-edit-state="true"] .nf-field-label{-webkit-transform:translateY(0);transform:translateY(0)}';
        const styleEle = document.createElement('style')
        styleEle.type = 'text/css';
        styleEle.appendChild(document.createTextNode(styles))
        document.body.appendChild(styleEle);
    }

    focusHandler(event) {
        // On focus always set editState to active
        this.getLabel(event.target).dataset.editState = true;
    }

    blurHandler(event) {
        // On blur check if field has a value
        if (event.target.value !== "")
            // If input is given, keep editState active
            this.getLabel(event.target).dataset.editState = true;
        else
            // Set editState to false if no input is given
            this.getLabel(event.target).dataset.editState = false;
    }

    getLabel(target) {
        // Get the parent object of the targeted field
        return target.closest('.field-wrap');
    }

}

jQuery(document).on( 'nfFormReady', function() {
    const fieldHandler =  new fieldController();
});