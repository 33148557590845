class menuController {
    constructor(target) {

        this.data = {
            header : target,
            subMenus : target.getElementsByClassName('menu-item-has-children'),
            navButton : document.getElementById('openNavigation'),
            navigation : document.getElementById('mainNavigation')
        }

        this.scrollListener();

        if (this.data.subMenus)
            this.subMenuController();

        this.data.navButton.addEventListener('click', this.openResponsive.bind(this));

        window.addEventListener('resize', e => {
            [].forEach.call(this.data.subMenus, (item, index) => {
                item.style.width = "";
                item.querySelector('.sub-menu').style.width = "";
            });
            this.subMenuController();
        })

    }

    scrollListener() {        
        document.addEventListener('scroll', e => {
            if (window.scrollY >= 100)
                this.data.header.classList.add('page-has-scrolled');
            else 
                this.data.header.classList.remove('page-has-scrolled');
        }, {passive: true})
    }

    subMenuController() {
        [].forEach.call(this.data.subMenus, (item, index) => {
            const link = item.querySelector('a');
            const containerWidth = item.offsetWidth;

            if (window.innerWidth >= 768) {
                item.style.width = `${(link.offsetWidth + 20)}px`;
                item.querySelector('.sub-menu').style.width = `${(containerWidth + 20)}px`;
            } else {
                item.style.width = ``;
                item.querySelector('.sub-menu').style.width = ``;
            }

            //Mobile clicker and link prevention
            item.dataset.isClicked = false;

            link.addEventListener('click', e => {
                e.preventDefault();
                let switcher = (item.dataset.isClicked === "true")
                item.dataset.isClicked = !switcher
            })
        })

        if (window.innerWidth <= 768) {
            [].forEach.call(this.data.subMenus, (item, index) => {
                const height = item.offsetHeight;
                item.style.maxHeight = `5.3em`;

                item.addEventListener('click', event => {
                    this.responsiveSubMenuClick(event, height)
                })
            })
        }
    }

    responsiveSubMenuClick(event, height) {
        const item = event.target.parentElement;
        let switcher = (item.dataset.isClicked === "true");

        if (switcher)
            item.style.maxHeight = `${(height * 2.5)}px`;
        else 
            item.style.maxHeight = `5.3em`;
    }

    openResponsive() {
        const {navButton, navigation, header} = this.data;
        let activeMenu = !(navButton.dataset.isActive === 'true')

        navButton.dataset.isActive = activeMenu;
        navigation.dataset.isActive = activeMenu;
        header.dataset.isActive = activeMenu;
    }

}

const menuClassContainer = new menuController(document.querySelector('header.header'));